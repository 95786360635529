body {
  margin: 0;
  font-family: "Oswald", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* oswald-300 - latin-ext_cyrillic */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/oswald-v35-latin-ext_cyrillic-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/oswald-v35-latin-ext_cyrillic-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/oswald-v35-latin-ext_cyrillic-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/oswald-v35-latin-ext_cyrillic-300.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/oswald-v35-latin-ext_cyrillic-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/oswald-v35-latin-ext_cyrillic-300.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-regular - latin-ext_cyrillic */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/oswald-v35-latin-ext_cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/oswald-v35-latin-ext_cyrillic-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/oswald-v35-latin-ext_cyrillic-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/oswald-v35-latin-ext_cyrillic-regular.woff") format("woff"),
    /* Modern Browsers */
      url("./fonts/oswald-v35-latin-ext_cyrillic-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/oswald-v35-latin-ext_cyrillic-regular.svg#Oswald")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/open-sans-v17-latin_cyrillic-300.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("./fonts/open-sans-v17-latin_cyrillic-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/open-sans-v17-latin_cyrillic-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/open-sans-v17-latin_cyrillic-300.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/open-sans-v17-latin_cyrillic-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/open-sans-v17-latin_cyrillic-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}
