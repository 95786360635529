.Input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.Label {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}
.innerText {
  font-size: 18px;
}
.InputElement::placeholder {
  color: #00000091;
}
.InputElement {
  font-size: 1.25em;
  -moz-appearance: none;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
  color: black;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}
.InputElement:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.InputElement::-ms-expand {
  display: none;
}

.InputElement option {
  padding: 0;
  font-size: 0.9em;
  font-family: "Oswald", "Open Sans", sans-serif;
  background-color: #ffffff;
  color: black;
}

.InputElement:focus {
  outline: none;
  color: black;
}

.Invalid {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid red;
}
#contained-modal-title-vcenter{
  font-size: 18px !important;
}

.modal-title{
  font-size: 18px !important;
}
