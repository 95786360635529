html {
  font-family: "Oswald", sans-serif;
}
body {
  font-family: "Oswald", sans-serif;
  background-image: url("./back.webp");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
  overflow: hidden;
}
.btn {
  font-weight: 200 !important;
}
h4 {
  font-size: 1.25rem !important;
}

.h4 {
  font-size: 1.25rem !important;
}

h4, .h4 {
  font-size: 1.25rem !important;
}

.App {
  text-align: center;
}
.overall {
  margin-top: 2%;
}
option {
  background-color: white;
  color: black;
}
taze {
  font-size: 18px !important;
  font-weight: 300 !important;
}
.small {
  font-size: 16px;
  padding-bottom: 5px;
  font-weight: 400;
}
.small2 {
  font-size: 13px;
  font-weight: 300;
}
.small3 {
  font-size: 18px;
  font-weight: 300;
}
.myButton {
  padding: 0.3rem 1.5rem;
  border-radius: 0.3rem;
  border: 2px solid black;
  color: black;
  background: transparent;
}
.myButton:hover {
  background-color: rgba(128, 148, 146, 0.548);
}
span {
  color: black;
}
input::placeholder {
  color: #cccccc;
}
.inputContainer {
  position: relative;
  margin: 0;
}

.suggestions {
  position: absolute;
  margin: 0;
  z-index: 1;
  background-color: #ffffff;
  width: 100%;
  border: 1px solid #ccc;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}
.suggestions > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.suggestions li {
  padding: 0.5rem;
  cursor: pointer;
  width: 100%;
}
.suggestions li:hover {
  background-color: #dfdfdf;
}

@media print {
  .print {
    color: black;
    background-color: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding-top: 100px;
    font-size: 30px;
    z-index: 9;
  }
  .print h1,
  h3 {
    color: black !important;
  }
  .btn {
    display: none;
  }
  a {
    display: none;
  }
}
.input-group-text {
  border-radius: 0;
  border-right: 0;
}
.date {
  border: 1px solid #ccc;
  padding: 0.43rem;
  width: 100%;
  color: rgb(0, 0, 0);
  background-color: rgba(204, 204, 204, 0);
}

.react-datepicker__year-read-view--selected-year {
  color: black;
}
.react-datepicker__year-option--selected {
  color: black;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  display: block;
}
.date:focus {
  outline: none;
  border: 2px solid black;
}
